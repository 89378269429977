.fileUploadLabel::before {
  content: url("https://leapassets.s3.ap-south-1.amazonaws.com/cdn-images/upload.svg");
  vertical-align: middle;
  margin-right: 13px;
}

.fileUploadLabel.active::before {
  color: #00b0aa;
  content: url("https://leapassets.s3.ap-south-1.amazonaws.com/cdn-images/tick.svg");
  vertical-align: sub;
}

.fileUploadLabel.error::before {
  color: #ff695b;
  content: url("https://leapassets.s3.ap-south-1.amazonaws.com/cdn-images/red-exclamation.svg");
  vertical-align: sub;
}

.fileUploadLabel.loading::before {
  content: '';
  margin-right: 0;
}

.spinnerHolder {
  margin-right: 15px;
  display: inline-block;
}

.spinnerHolder svg {
  stroke: #00b0aa;
}

.fileUploadCta {
  background: #E0E0E0;
  border-radius: 4px;
  padding: 4px 10px;
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: right;
  color: #443EFF;
}

.filename {
  display: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #828282;
  margin-right: 15px;
  vertical-align: middle;
}