@tailwind base;
@tailwind components;
@tailwind utilities;

// @import "public/static/scss/Carousel.scss";
// @import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
// @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500;600;700&display=swap');

html {
  scroll-behavior: smooth;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

* {
  outline: none !important;
  font-family: "PaulGrotesk", sans-serif !important;
}

.verloop-button {
  display: none;
}

#leapBrandName {
  font-family: "Oswald", sans-serif !important;
}

/*  react -collapsible css */

.Collapsible > .is-open > .collapseHeader {
  background: #fff6d9 !important;
}
.Collapsible > .is-closed > .collapseHeader {
  border-bottom: 0.5px solid #e8e3e3;
}

.terms-and-conditions p {
  margin-bottom: 1rem;
}
.terms-and-conditions ol {
  list-style: number;
  margin-bottom: 1rem;
  margin-left: 1rem;
}

.Collapsible > .Collapsible__contentOuter > .Collapsible__contentInner {
  border: 1px solid #ff7791;
  border-top: none;
}

.Collapsible__trigger {
  font-size: 14px;
}

.Collapsible__contentInner {
  font-size: 14px;
}

.main_testimonial {
  .slick-dots {
    bottom: -50px !important;
    > li {
      width: 10px !important;
      height: 10px !important;
      background-color: #3b3d7f;
      border-radius: 100%;
      > button {
        width: 10px !important;
        height: 10px !important;
      }
      & > button:before {
        font-size: 0 !important;
        width: 10px !important;
        height: 10px !important;
      }
      @media (max-width: 767px) {
        width: 10px !important;
        height: 10px !important;
      }
    }
    > .slick-active {
      background-color: #ffffff;
    }
    @media (max-width: 767px) {
      bottom: -35px !important;
    }
  }
}

.drawer {
  background-color: #2c2c2c;
  padding: 2rem 1.5rem;
  overflow-y: scroll;
  height: calc(100vh - 65px);
}

.arrow {
  transition: ease all 0.25s;
}
.rotateArrow {
  transform: rotate(180deg);
}

.videoDrawer {
  background-color: #2c2c2c;
  padding: 2rem 1.5rem;
  overflow-y: scroll;
  z-index: 30;
  @media (min-width: 767px) {
    height: calc(100vh - 250px);
  }
}

.drawerText {
  color: white;
  opacity: 0.9;
}

.drawerSubText {
  color: white;
  opacity: 0.6;
}

.spotCounsellingCalenderCard {
  & > div.slick-slider {
    padding: 0px 25px 0 40px;
    @media (max-width: 767px) {
      padding: 0 5px 0 30px;
    }
  }
}

.sendbird-theme--light .sendbird-conversation {
  background-color: #faf9f7 !important;
  border: none !important;
}

.sendbird-conversation__footer {
  background-color: #faf9f7;
}
.sendbird-conversation__scroll_container {
  overflow-y: hidden !important;
}

#sendbird-dropdown-portal {
  display: none !important;
}

.sendbird-dropdown__menu {
  display: none !important;
}

.sendbird-message-item-menu__trigger {
  display: none !important;
}

.stickyDownloadNudgeButton {
  width: 70px;
  height: 24px;
  // padding: 4px 8px 3px;
  background: #ffffff;
  border: 1px solid #878aef;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-align: center;
  color: #443eef;
}

#menu-toggle:checked + #menu {
  display: block;
}

@keyframes reduceWidth {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
